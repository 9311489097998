import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import RichText from "../components/rich-text"
import styled from "styled-components"
import { mq, site } from "../styles/variables"

const Text = styled.div`
  max-width: 672px;
`

const TeamMembers = styled.div`
  margin: 60px auto;
  max-width: ${site.width}px;

  @media ${mq.gte("m")} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${site.gridGutter}px;
    margin-top: 0;
    margin-bottom: 400px;
  }
`

const Extra = styled.div`
  pointer-events: none;
  transition: opacity 300ms;
  background: white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 0 ${site.gutter.s}px 20px;
  margin: 0 -${site.gutter.s}px;

  @media ${mq.gte("l")} {
    padding: 0 20% 8%;
    margin: 0 -20%;
  }

  .no-js & {
    position: static;
    opacity: 1;
    padding: 0;
    background: transparent;
  }
`

const Top = styled.div`
  margin: 0 -${site.gutter.s}px 10px;
  padding: 23px ${site.gutter.s}px 2px;
  transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1);

  .is-open & {
    background: white;
  }

  @media ${mq.gte("m")} {
    padding: 20px 20px 0;
    margin: 0 -20px;
  }

  @media ${mq.gte("l")} {
    padding: 20% 20% 0;
    margin: 0 -20%;
  }
`

const Heading = styled.h2`
  font-size: 22px;
  margin: 1em 0 0;
  padding: 0 0 0.5em;
  transform-origin: 0 50%;
`

const TeamMember = styled.div`
  font-size: 16px;
  position: relative;
  cursor: pointer;

  &.is-open ${Extra} {
    opacity: 1;
    pointer-events: initial;
  }

  > div {
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media ${mq.gte("m")} {
    grid-column-start: span 6;
  }

  @media ${mq.gte("l")} {
    width: auto;
    grid-column-start: 1;
    grid-column-end: span 3;

    &:nth-child(3n + 2) {
      grid-column-start: 5;
    }

    &:nth-child(3n + 3) {
      grid-column-start: 9;
    }
  }
`

const StyledImage = styled(Img)`
  @media ${mq.lt("m")} {
    width: 73.433%;
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);

    .is-open & {
      width: 100%;
    }
  }

  > div {
    padding-bottom: 87.463% !important;

    @media ${mq.gte("l")} {
      padding-bottom: 113.122% !important;
    }
  }
`

const Team = ({
  location,
  data: {
    sanityTeam: {
      description,
      title,
      _rawRichtext,
      teamMembers,
      _rawTeamMembers,
    },
  },
}) => {
  const [open, setOpen] = useState()
  const [zIndex, setZIndex] = useState(1)

  return (
    <Layout
      color="#F4E8E4"
      location={location}
      title={title}
      description={description}
      onClick={() => setOpen(null)}
    >
      <h1 className="reveal">{title}</h1>

      <Text className="reveal">
        {_rawRichtext && <RichText blocks={_rawRichtext} />}
      </Text>

      {teamMembers && (
        <TeamMembers>
          {teamMembers.map(({ _key, title, role, image }, i) => {
            image.asset.fluid.sizes = `
              (min-width: 1440px) 276px,
              (min-width: 1200px) 19vw,
              (min-width: 768px) 33vw,
              100vw
            `

            return (
              <TeamMember
                key={_key}
                onClick={(e) => {
                  e.stopPropagation()
                  e.currentTarget.style.zIndex = zIndex
                  setOpen(open === _key ? null : _key)
                  setZIndex(zIndex + 1)
                }}
                className={open === _key ? "is-open" : "reveal"}
              >
                <div style={{ opacity: !open ? 1 : open === _key ? 1 : 0.1 }}>
                  <Top>
                    <StyledImage fluid={{ ...image.asset.fluid }} />
                    <Heading>{title}</Heading>
                  </Top>

                  <Extra>
                    <p>{role}</p>
                    <RichText blocks={_rawTeamMembers[i].richtext} />
                  </Extra>
                </div>
              </TeamMember>
            )
          })}
        </TeamMembers>
      )}
    </Layout>
  )
}

export default Team

export const query = graphql`
  query TeamPageQuery {
    sanityTeam {
      description
      title
      _rawRichtext
      teamMembers {
        _key
        title
        role
        image {
          asset {
            fluid {
              src
              srcSet
              aspectRatio
              base64
              sizes
            }
          }
        }
      }
      _rawTeamMembers
    }
  }
`
